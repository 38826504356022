import React from 'react'
import ReactDOM from 'react-dom'
import App from './App.jsx'
import './images/favicon.ico'
import { ClientProvider } from './utils/ClientContext'
import './developer-portal-main.less'

const Root = () => (
    <ClientProvider>
        <App />
    </ClientProvider>
)
const rootEl = document.getElementById('root')

ReactDOM.render(<Root />, rootEl)
