import React, { lazy, Suspense } from 'react'
import { Grid } from '@sb1/ffe-grid-react'
import { HashRouter as Router, Route, Switch } from 'react-router-dom'
import Footer from './components/Footer/Footer'
import Header from './components/Header/Header'
const SorOstMerger = lazy(() => import('./components/SorOstMerger'))
const ApiOverview = lazy(() => import('./pages/Apis/ApiOverview'))
const Home = lazy(() => import('./pages/Home/Home'))
const PersonalClientForm = lazy(() => import('./pages/PersonalClientForm'))
const ClientEdit = lazy(() => import('./pages/ClientEdit'))
const Api = lazy(() => import('./pages/Api/Api'))
const Login = lazy(() => import('./pages/Login/Login'))
const Page404 = lazy(() => import('./pages/Page404'))
const DocumentView = lazy(() => import('./pages/DocumentView'))
const GettingStarted = lazy(() => import('./pages/GettingStarted/GettingStarted'))
const FAQPage = lazy(() => import('./pages/FAQ/FAQ'))
const PSD2Page = lazy(() => import('./pages/PSD2/PSD2'))

/**
 * Used to route users to the bank's Open Banking page
 * temporarily while we figure out where to send the form in
 * GettingStartedPartner
 * @returns {null}
 */
const temporaryExternalLinkHack = () => {
    window.location.href = 'https://www.sparebank1.no/nb/bank/bedrift/open-api.html'
    return null
}

const App = () => {
    return (
        <Router>
            <React.Fragment>
                <Grid className="frontpage" topPadding={false}>
                    <Header />
                    <Suspense fallback={<div>Loading...</div>}>
                        <main role="main" id="main-content">
                            <Switch>
                                <Route path="/" exact={true}>
                                    <Home showAllApis={false} />
                                </Route>
                                <Route path="/showall" exact={true}>
                                    <Home showAllApis={true} />
                                </Route>
                                <Route path="/login" exact={true} component={Login} />
                                <Route path="/documentation/gettingstarted" exact={true}>
                                    <GettingStarted />
                                </Route>
                                <Route
                                    path="/documentation/gettingstarted-partner"
                                    exact={true}
                                    component={temporaryExternalLinkHack}
                                />
                                <Route path="/documentation/faq" exact={true}>
                                    <FAQPage showLinks={true} />
                                </Route>
                                <Route
                                    path="/personal-client/create"
                                    exact={true}
                                    component={PersonalClientForm}
                                />
                                <Route path="/client/edit/:id" exact={true}>
                                    <ClientEdit />
                                </Route>
                                <Route path="/api/:id" exact={true}>
                                    <Api />
                                </Route>
                                <Route path={'/apis'} exact={true}>
                                    <ApiOverview showAll={false} />
                                </Route>
                                <Route path={'/apis/showall'} exact={true}>
                                    <ApiOverview showAll={true} />
                                </Route>
                                <Route path="/documentation/psd2" exact={true}>
                                    <PSD2Page />
                                </Route>
                                <Route
                                    path={'/documentation/:documentPath'}
                                    component={DocumentView}
                                />
                                <Route path={'/sor-ost-merger'} component={SorOstMerger} />
                                <Route path="*" component={Page404} />
                            </Switch>
                        </main>
                        <Footer />
                    </Suspense>
                </Grid>
            </React.Fragment>
        </Router>
    )
}

export default App
